/* NotificationPopup.module.css */
.popup {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #ffffff;
    border-left: 5px solid #007BFF;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    padding: 15px 20px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    z-index: 9999;
    width: 320px;
    animation: slideIn 0.4s ease-out;
  }
  
  .image {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .content {
    display: flex;
    flex-direction: column;
  }
  
  .title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  
  .message {
    margin: 4px 0 0;
    font-size: 14px;
    color: #666;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0%);
      opacity: 1;
    }
  }
  
  /* Add to NotificationPopup.module.css */
.closeButton {
    position: absolute;
    top: 6px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    color: #888;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .closeButton:hover {
    color: #333;
  }
  