/* NodeDetails.module.css */

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 26rem; /* max width for card */
  padding: .5rem;
  background-color: #ffffff;
  border: 1px solid #e2e8f0; /* light gray border */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #2d3748; /* dark gray text color */
  font-family: Arial, sans-serif;
  height: 100vh;
  overflow: scroll;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #4a5568;
}

.close {
  background: none;
  border: none;
  color: #a0aec0;
  font-size: 1.25rem;
  cursor: pointer;
  transition: color 0.2s;
}

.close:hover {
  color: #e53e3e; /* red when hovering */
}

.imageContainer {
  display: flex;
  justify-content: center;
  margin: .5rem 0;
}

.image {
  width: 100%;
  /* height: 15rem; */
  /* border-radius: 50%; */
  border: 3px solid #edf2f7; /* light border */
}

.details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detailsItem {
  display: flex;
  justify-content: space-between;
}

.detailsLabel {
  font-weight: 500;
  color: #718096;
}

.detailsValue {
  font-weight: 400;
  color: #2d3748;
}

.sectionTitle {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: #4a5568;
}
