/* Overlay to darken the background */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999; /* Ensure it's above other elements */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Popup container */
  .popup {
    background-color: #fff;
    padding: 20px;
    width: 90%;
    height: 98vh;
    overflow: auto;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1000; /* Ensure it's above the overlay */
    position: relative;
  }
  
.formContainer {
  background-color: white;
  padding: 15px;
  width: 90%;
  min-width: 20rem;
  max-width: 320px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profileImageContainer img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.formGroup {
  margin-bottom: 15px;
}

.formActions {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.formActions button{
  width: 40% !important;
}