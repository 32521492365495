.root {
  position: absolute;
  display: flex;
  padding: 10px;
}

.inner {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  /* border: 2px solid rgba(0, 0, 0, .2); */
  cursor: pointer;
}
.male, .female{
  background-color: #ffffff;
  border-radius: .2rem;
}
.isRoot {
  border-color: rgba(0, 0, 0, .4);
}

.isHover {
  border-color: rgba(0, 0, 0, .8);
}

.id {
  font-size: 7px;
  line-height: 1;
  opacity: .5;
}

.sub {
  position: absolute;
  top: 6px;
  right: 14px;
  width: 14px;
  height: 10px;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 4px 0;
  background: #fff;
  cursor: pointer;
}

.sub.male {
  background: #fdaed8;
}

.sub.female {
  background: #a4ecff;
}

.family-tree {
  display: flex;
  gap: 1.875rem;
}

.node {
  display: flex;
  align-items: center;
  width: 11.25rem; /* 180px */
  padding: 0.625rem; /* 10px */
  border-radius: 0.3125rem; /* 5px */
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  position: relative;
  opacity: 1;
  z-index: 999;
}

.node.male {
  border: 0.125rem solid dodgerblue; /* 2px */
}
.node.female {
  border: 0.125rem solid orange; /* 2px */
}

.profileContainer {
  position: relative;
  display: inline-block;
  width: 3.75rem; /* 60px */
  height: 3.75rem; /* 60px */
  border: 0.0625rem solid #ccc; /* 1px */
  border-radius: 50%; /* Circular */
  overflow: hidden;
  margin-right: 0.9375rem; /* 15px */
}

.profilePic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
/* Pen Icon - Bottom Right */
.editIcon {
  position: absolute;
  bottom: 0.3125rem; /* 5px */
  right: 0.3125rem; /* 5px */
  font-size: 0.75rem; /* 12px */
  color: gray;
  cursor: pointer;
}
.editIcon:hover {
  color: black;
}

.cameraIcon {
  position: absolute;
  bottom: 0;
  left: 25%;
  transform: translateY(-50%);
  font-size: 0.5rem; /* 8px */
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 0.1875rem; /* 3px */
  cursor: pointer;
}
.cameraIcon:hover {
  color: black;
  background-color: rgba(0, 0, 0, 0.8);
}


.addIcon {
  position: absolute;
  top: 0.3125rem; /* 5px */
  right: 0.3125rem; /* 5px */
  font-size: 0.75rem; /* 12px */
  color: gray;  /* Icon color */
  cursor: pointer; /* Pointer cursor on hover */
}
.addIcon:hover {
  color: black; /* Change color on hover */
}
/* Details for Name and Birth Year */
.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.name {
  font-weight: bold;
  font-size: 0.75rem; /* 12px */
}
.birthYear {
  margin-top: .3rem;
  color: rgb(56, 55, 55);
  font-size: 0.625rem; /* 10px */
}

.menu {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); 
  /* top: 50px; */
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1001;
  width: 200px;
  padding: 10px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu ul li {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  text-align: left;
}

.menu ul li:hover {
  background-color: #f0f0f0;
  color: #007BFF;
}

/* Close button styling */
.closeButton {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #999;
}

.closeButton:hover {
  color: #333;
}

/* Overlay to darken the background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Below the menu but on top of everything else */
}

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.formContainer {
  background-color: white;
  padding: 15px;
  width: 90%;
  min-width: 20rem;
  max-width: 320px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profileImageContainer img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.formGroup {
  margin-bottom: 15px;
}

.formActions {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.formActions button{
  width: 40% !important;
}


.refmenu {
  position: absolute; /* Allow dynamic positioning based on JS */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 150px;
  padding: 10px;
  z-index: 1001; /* Make sure it appears above other nodes */
}

.refmenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.refmenu li {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.refmenu li:hover {
  background-color: #f0f0f0;
}

.refmenu .closeMenuButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333; /* Icon color */
  transition: color 0.3s ease;
}

.refmenu .closeMenuButton:hover {
  color: #ff6347; /* Change color on hover */
}


.deleteDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.deleteDialogContent {
  background-color: #fff;
  padding: 20px;
  max-width: 300px;
  width: 100%;
  text-align: center;
}

.deleteDialogContent h4 {
  font-size: 15px;
  margin-bottom: 20px; /* Optional: Adjust spacing for better layout */
}

.deleteButton, .cancelButton {
  margin: 10px;
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
}

.deleteButton {
  background-color: red;
  color: white;
}

.cancelButton {
  background-color: gray;
  color: white;
}
