* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  line-height: 1.5;
  color: #222;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
  -webkit-user-drag: none;
  font-feature-settings: "pnum" 1, "kern" 1, "liga" 1;
}

#root {
  height: 100%;
}
