/*@font-face {
  font-family: 'DS-Digital';
  src: url('../fonts/DS-DIGI.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}*/

.login-section {

}

.login-background {
  background-image: url("../img/login-background.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.register-background {
  background-image: url("../img/register-background.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.activate-background {
  background-image: url("../img/activate-background.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.tree-background {
  background-image: url("../img/tree-background.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
}


.dashed {
  border-bottom: 0.0625rem dashed #d5e2e6;
}

#cookie-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  z-index: 9999;
}
#cookie-consent {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  display: none;
  z-index: 10000;
}
#cookie-consent.active {
  display: block;
}
#cookie-consent .message {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
#cookie-consent .button {
  background-color: #f39c12;
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.swiper-container {
  width: 100%;
  height: 600px; /* Set the desired height for your slideshow */
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}

.img-circle {
  border-radius: 50%;
}

.profile-user-img {
  border: 3px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 100px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.profile-tree-img {
  border: 2px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 150px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.tree-img {
  border: 2px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 50px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* Add custom styles for the spinner and modal */
    #processing-modal .fa-spinner {
      font-size: 3rem;
      color: #28a745; /* Green color */
    }

    #processing-modal h4 {
      margin-top: 1rem;
      color: #333; /* Text color */
    }

.title-white {
  color : #fff;
  text-shadow: -2px 5px 6px rgba(0,0,0,0.6);
}

.text-strong {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700; /* Bold (700) font weight */
}

.bg-light {
  background-color: #fbe5b6 !important;
}
.bg-brown {
  background-color: #1f3d1f !important;
}
.bg-green {
  background-color: #1f3d1f !important;
}
.bg-gold {
  background-color: #f8b735 !important;
}
.bg-orange {
  background-color: #f8b735 !important;
}

.btn.btn-flat {
  border-radius: 0;
  border-width: 1px;
  box-shadow: none;
}

.btn-group-vertical .btn.btn-flat:first-of-type, .btn-group-vertical .btn.btn-flat:last-of-type {
  border-radius: 0;
}

.avatars {
  display: inline-block;
  transform: scale(-1, 1);
}

.avatar {
  position: relative;
  display: inline-block;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 30px;
}

.avatar:not(:first-child) {
  margin-left: -15px;
}

.avatar img {
  width: 100%;
  display: block;
  transform: scale(-1, 1);
}

#regForm {
    background-color: #ffffff;
    font-family: 'Quicksand', sans-serif;

}


input {
    padding: 10px;
    width: 100%;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    border: 1px solid #aaaaaa
}

input.invalid {
    background-color: #ffdddd
}

.tab {
    display: none
}

button {
    background-color: #4CAF50;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    cursor: pointer
}

button:hover {
    opacity: 0.8
}

#prevBtn {
    background-color: #bbbbbb
}

.step {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbbbbb;
    border: none;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5
}

.step.active {
    opacity: 1
}

.step.finish {
    background-color: #4CAF50
}

.all-steps {
    text-align: center;
}

.thanks-message {
    display: none
}


.card-dotted {
  border : 2px dashed #666666;
  background-color: rgba(255, 255, 255, 0.3);
}

.dashed {
  border-bottom: none; /* Remove any default solid line */
  border-bottom: 0.0625rem dashed #0B296C; /* Add dashed line */
}


.boxed {
  border : 2px solid #666666;

}

.family-tree-img {
  border: 3px solid #0B296C;
  margin: 0 auto;
  padding: 3px;
  width: 100px;
}

.bg-gray {
  background-color : #ECECFB;
}

.img-size-50 {
  width: 50px;
}