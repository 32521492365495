.bellWrapper {
    position: relative;
    display: inline-block;
  }
  
  .redDot {
    position: absolute;
    top: 0px;
    right: 2px;
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    border: 1px solid white;
  }
  