@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

/* Overlay styling */
.overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 25rem;
  background-color: rgba(0, 0, 0, 0.815);
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Form container styling */
.formContainer {
  background-color: white;
  padding: 0.8rem;
  width: 30rem;
  font-family: 'Lato', sans-serif;
  /* max-width: 300px; */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 1.6rem !important;
  text-align: center;
  margin-bottom: 0.3rem;
  font-weight: normal;
}

.formGroup {
  margin-bottom: 0.35rem;
}

.formGroup label {
  display: block;
  margin-bottom: 0.1rem;
  font-size: 0.8rem;
}

.formGroup input {
  width: 100%;
  padding: 0.25rem;
  font-size: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.formGroup input:focus {
  border-color: #F56932;
  box-shadow: 0 0 5px rgba(245, 105, 50, 0.5);
  outline: none;
}

/* Form actions */
.formActions {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.formActions button {
  padding: 0.3rem 0.3rem !important;
  font-size: 0.8rem;
  border: none;
  cursor: pointer;
}

.cancelBtn {
  /* margin-left: 1rem; */
  background-color: #ccc;
  color: #333;
}

.cancelBtn:hover {
  background-color: #bbb;
}

.formActions button[type="submit"] {
  background-color: #F56932;
  color: white;
}

/* Responsive styling */
@media (max-width: 600px) {
  .formContainer {
    padding: 0.5rem;
  }
}
