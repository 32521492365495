.root {
  display: flex;
  flex-direction: column;
  background: #eee;
  height: 100%;
}

.header {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-bottom: 1px solid #ddd;
  z-index: 999;
}

.title {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 500;
}

.version {
  display: block;
  padding: 4px 6px;
  font-size: 10px;
  line-height: 1;
  background: #8a8989;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.wrapper {
  flex: 1;
}

.tree {
  background: #eeee;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.reset {
  position: absolute;
  right: 10px;
  top: 60px;
  padding: 4px 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  line-height: 1;
  cursor: pointer;
  color: rgb(226, 85, 85);
}

.details {
  position: absolute;
  left: 10px;
  top: 60px;
}

@media (max-width: 600px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .details {
    top: 100px;
  }
}

.emptyMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.emptyMessageContent {
  text-align: center;
  max-width: 60%;
  padding: 20px;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 25px;
  width: 90%;
  max-width: 30rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  height: 95vh;
  overflow: auto;
}

.popup h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  text-align: center;
}
/* .popup form{
  min-width: 20rem;
} */
/* Form input styling */
form input,form select {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  box-sizing: border-box;
}

form button[type="submit"] {
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
}

form button[type="submit"]:hover {
  background-color: #0056b3;
}

form button[type="button"] {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
}

form button[type="button"]:hover {
  background-color: #5a6268;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #333;
}

.backButton {
  width: 5rem;
  margin-left: 0.5rem;
  /* margin-top: 2rem;
  margin-left: 1rem; */
}

@media (max-width: 600px) {
  .popup {
    padding: 15px;
    height: 100vh;
    width: 100%;
    border-radius: 0;
  }

  form input,
  form select {
    padding: 8px;
    font-size: 16px;
  }

  form button[type="submit"],
  form button[type="button"] {
    padding: 12px;
  }

  .backButton {
    width: 100%;
    margin: 0.5rem 0;
  }

}
.hidden {
  display: none !important;
}
.backButton2{
  width: fit-content;
  margin: 0.5rem 0;
  
    }

    

.deleteDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.deleteDialogContent {
  background-color: #fff;
  padding: 20px;
  max-width: 300px;
  width: 100%;
  text-align: center;
}

.deleteDialogContent h4 {
  font-size: 15px;
  margin-bottom: 20px; /* Optional: Adjust spacing for better layout */
}

.deleteButton, .cancelButton {
  margin: 10px;
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
}

.deleteButton {
  background-color: red;
  color: white;
}

.cancelButton {
  background-color: gray;
  color: white;
}
